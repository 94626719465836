import React from "react"
import { graphql } from "gatsby"
import Layout from "@/components/layout"
import SEO from "@/components/seo"
import Sections from "@/components/sections"
import ContentPost from "@/components/content/content-post"
import "../styles/app.scss"

const DynamicPage = ({ data, pageContext }) => {
  return (
    <>
      <SEO page={data.strapi.content.meta} site={data.strapi.meta.data} fallback={data.site.siteMetadata} />
      <Layout>
      <ContentPost post={data.strapi.content}/>
      </Layout>
    </>
  )
}

export default DynamicPage

export const query = graphql`
  query PostQuery($id: ID!) {
    site {
      siteMetadata {
        description
        siteUrl
        title
      }
    }
    strapi {
      content(id: $id) {
        id
        content
        author
        slug
        title
        content_type {
          id
          type
          slug
        }
        date
        meta {
          description
          title
          image {
            formats
            url
            height
            width
          }
        }
        image {
          alternativeText
          height
          mime
          url
          width
        }
      }
      meta {
        data {
          description
          title
          image {
            formats
            url
            width
            height
          }
        }
      }
    }
  }
`