import React from "react"
import CustomLink from '../custom-link'
import Image from "../image";

const ContentPost = ({post}) => {
  const createMarkup = (markup) => {
    return {__html: markup};
  }
  return (
    <div className={`content-post`}>
    <div className='content-post__breadcrumb'>
    <div className='container-wide'>
      <CustomLink className='content-post__breadcrumb-base' link={{url: `/${post.content_type.slug}`, newTab: false}}>
        {post.content_type.type}
      </CustomLink>
        <span className='content-post__breadcrumb-current'> &rarr; {post.title}</span>
      </div>
    </div>
    { post.image ? (
      <div className="content-post__image-container container">
        <Image media={ post.image } className="content-post__image" />
      </div>
    ) : null }
      <div className={`content-post__post`}>

        <h1 className="content-post__title container-narrow h2">{post.title}</h1>
        <div className="content-post__content container-narrow" dangerouslySetInnerHTML={createMarkup(post.content)} />
      </div>
    </div>
  )
}
export default ContentPost
